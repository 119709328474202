<template>
  <div
    class="track"
    :class="{ active: active }"
    :key="item.position"
    @click="toggleActive()"
  >
    <div class="track-container">
      <div class="number">
        {{ index + 1 }}
        <img v-if="active" src="~@/assets/i/active-track.svg" alt="" />
      </div>
      <Marquee  class="name"
        :text="
          contentType === 'Book' && !item.title
            ? $t('album.part1') + ' ' + (index + 1) + $t('album.part2')
            : item.title
        "
      >
      </Marquee>
      <div class="time">
        {{ timeString }}
      </div>
    </div>
    <div v-if="active" class="progress">
      <span :style="timeStyle"></span>
    </div>
  </div>
</template>

<script>
import utils from "@/lib/utils";
import Marquee from "./Marquee.vue";

export default {
  name: "Track",
  props: ["item", "index", "contentType", "active", "time"],
  components: {
    Marquee,
  },
  computed: {
    timeString() {
      return utils.buildTimeString(this.item.durationSec, false);
    },
    timeStyle() {
      let width = this.time / (this.item.durationSec / 100);
      if (width > 100) {
        width = 100;
      }
      return { width: width + "%" };
    },
  },
  methods: {
    toggleActive() {
      this.$emit("active-change", {
        position: this.item.position,
        status: true,
      });
    },
  },
};
</script>

<style scoped>

</style>
