<template>
  <div ref="div">
    <span ref="span">{{ text }}</span>
  </div>
</template>

<script>
export default {
  mounted() {
    let el = this.$refs.div;
    if (this.isOverflown(el)) {
      el.classList.add("marquee");

	  if (
        el.scrollWidth / el.clientWidth >= 1.1 &&
        el.scrollWidth / el.clientWidth < 1.2
      ) {
        el.classList.add("marquee10");
      } else if (
        el.scrollWidth / el.clientWidth >= 1.2 &&
        el.scrollWidth / el.clientWidth < 1.3
      ) {
        el.classList.add("marquee20");
      } else if (
        el.scrollWidth / el.clientWidth >= 1.3 &&
        el.scrollWidth / el.clientWidth < 1.4
      ) {
        el.classList.add("marquee30");
      } else  if (
        el.scrollWidth / el.clientWidth >= 1.4 &&
        el.scrollWidth / el.clientWidth < 1.5
      ) {
        el.classList.add("marquee40");
      } else if (
        el.scrollWidth / el.clientWidth >= 1.6 &&
        el.scrollWidth / el.clientWidth < 1.7
      ) {
        el.classList.add("marquee60");
      } else if (
        el.scrollWidth / el.clientWidth >= 1.7 &&
        el.scrollWidth / el.clientWidth < 1.8
      ) {
        el.classList.add("marquee70");
      } else if (el.scrollWidth / el.clientWidth >= 1.8) {
        el.classList.add("marquee80");
      } else {
		el.classList.add("marquee1");
	  }
      //   this.$refs.div.childNodes[0].style.animationDuration = 6
    }
  },
  methods: {
    isOverflown(element) {
		// console.log( element.scrollWidth / element.clientWidth)
      return (
        element.scrollHeight > element.clientHeight ||
        element.scrollWidth > element.clientWidth
      );
    },
  },
  name: "Marquee",
  props: {
    text: String,
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span {
  white-space: nowrap;
}
.marquee span {
  display: inline-block;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-timing-function:linear;
  animation-delay: 2s
  /* animation: marquee 10s alternate infinite; */
}
.marquee1 span {
  animation-duration: 6s;
  animation-name: marquee;
  /* animation: marquee 10s alternate infinite; */
}
@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-10%);
  }
}
.marquee10 span {
  animation-duration: 12s;
  animation-name: marquee10;
}

@keyframes marquee10 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-17%);
  }
}
.marquee20 span {
  animation-duration: 16s;
  animation-name: marquee20;
}

@keyframes marquee20 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-23%);
  }
}
.marquee30 span {
  animation-duration: 21s;
  animation-name: marquee30;
}

@keyframes marquee30 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-28%);
  }
}
.marquee40 span {
  animation-duration: 25s;
  animation-name: marquee40;
}

@keyframes marquee40 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-33%);
  }
}
.marquee60 span {
  animation-duration: 30s;
  animation-name: marquee60;
}

@keyframes marquee60 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-40%);
  }
}
.marquee70 span {
  animation-duration: 33s;
  animation-name: marquee70;
}

@keyframes marquee70 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-45%);
  }
}

.marquee80 span {
  animation-duration: 37s;
  animation-name: marquee80;
}

@keyframes marquee80 {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-55%);
  }
}
</style>
